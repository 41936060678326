import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"

const FaqPage = (props) => (
  <Layout location={props.location}>

        {/* Post */}
        <section class="post">
            <header>
            <h3>What is the RSVP Deadline?</h3>
            <span>May 15, 2019.</span>
            <p>But if you take longer than May 30, we will hunt you down.</p>
            </header>


            <h3>Who can I call with questions?</h3>
            <ul>
                <li>Alin at 587-899-8255</li>
                <li>Kevin at 780-700-1491</li>
            </ul>

            <h3>How do i get to the reception venue after the ceremony?</h3>
            <p>Both the ceremony venue and the reception venue are a 5 minute walk from the c-train station so whether you wish to taxi, Uber, or take the C-Train, the journey should take you no longer than 15 minutes.</p>

            <h3>Will I need to keep busy between the ceremony and the reception?</h3>
            <p>No as we expect the reception to start right after the ceremony.</p>

            <h3>Will I be served alcohol during the reception?</h3>
            <p>Yes. Alforno will have a fully stocked open bar for the evening.</p>

            <h3>What if I have dietary restrictions?</h3>
            <p>If you have dietary restrictions that aren't covered by our options (beef, chicken, and vegetarian), then just let us know as you RSVP so we can work with Alforno to make sure you have a delightful dinner.</p>

            <h3>Can I bring a date?</h3>
            <p>Look, I'm going to be straight with you: no. In an effort to keep our guest list on track and our wedding an intimate affair, we can only accommodate guests formally invited.</p>

            <h3>What should I wear?</h3>
            <p>Something you might wear to a cocktail reception amidst a botanical garden.</p>


            <h3>Where should I stay?</h3>
            <span>The short answer is you have several options &mdash; here are just a few:</span>

            <ul>
                <li>If you want to stay downtown (and right where our ceremony is being held): <a href="https://www.legermainhotels.com/en/calgary/">Le Germain Hotel</a></li>
                <li>If you want to stay near the river (and near the reception venue): <a href="https://www.hotelartskensington.com/">Hotel Arts Kensington</a></li>
                <li>If you just want to minimize the walk(/stupor) between the reception venue and where you rest your weary head: <a href="https://hotel.regencycalgary.com/">Regency Suites Hotel</a></li>
            </ul>
        </section>
  </Layout>
)

export default FaqPage
