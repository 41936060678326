/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import Nav from "./nav"
import BackgroundImage from 'gatsby-background-image'

import "./layout.css"
import "../styles/main.scss"
import { Z_FIXED } from "zlib";

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        desktop: file(relativePath: { eq: "bg.jpg" }){
          childImageSharp{
            fluid(quality:100, maxWidth: 4000){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
<BackgroundImage fluid={data.desktop.childImageSharp.fluid} style={{
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundSize:'cover',
  width: '100%',
  height: '100vh'

}}>
<div style={{maxHeight:'100%', overflowY:'auto'}}>
<Header siteTitle={data.site.siteMetadata.title} />
  <Nav path={location.pathname}/>
  <main id="main">{children}</main>
  {/* <footer id="footer">
    © {new Date().getFullYear()}, Built with
    {` `}
    <a href="https://www.gatsbyjs.org">Gatsby</a>
  </footer> */}
  <div id="copyright">
    <ul><li>Hi</li><li></li></ul>
  </div> 
</div>
</BackgroundImage>



      

    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
