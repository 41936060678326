import React from "react";
import { Link } from "gatsby";

function List(props) {
  const activePath = props.path;
  const linkPath = props.link;
  if (activePath === linkPath) {
    return <ActiveLink link={linkPath} text={props.text} />;
  } else {
    return <Li link={linkPath} text={props.text} />;
  }
}

function ActiveLink(props) {
  return (
    <li className="active">
      <Link to={props.link}>{props.text}</Link>
    </li>
  );
}

function Li(props) {
  return (
    <li>
      <Link to={props.link}>{props.text}</Link>
    </li>
  );
}

export default class Nav extends React.Component {
  render() {
    return (
      <div>
        <nav id="nav">
        <ul className="links">
            <List path={this.props.path} link={"/"} text={"Home"} />
            <List path={this.props.path} link={"/faq"} text={"FAQ"} />
            <List path={this.props.path} link={"/rsvp"} text={"RSVP"} />
        </ul>
        </nav>
        </div>
    );
  }
}
